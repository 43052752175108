export { LitTypewriter } from "lit-typewriter";
import "./styles/styles.scss";

document.addEventListener("mousemove", (event) => {
  const x = event.pageX;
  const y = event.pageY;
  updateGradient(x, y);
});

document.addEventListener("touchmove", (event) => {
  const x = event.touches[event.touches.length - 1].clientX;
  const y = event.touches[event.touches.length - 1].clientY;
  updateGradient(x, y);
});

const updateGradient = (x: number, y: number) => {
  const vw = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  const pctX = Math.round((x / vw) * 100);
  const pctY = Math.round((y / vh) * 100);

  document.body.style.backgroundImage = `
        radial-gradient(at ${pctX}% ${pctY}%, #d76d77, #5c3a70)
    `;
};
